/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui"

import { graphql } from 'gatsby'
import Layout from "../components/layout"

import SEO from "../components/seo"

import ShapedCard from '../components/ShapedCard'
import BgImage from '../components/BgImage'
import Button from '../components/button'
import Card from '../components/Card'
import AdvancedSlider from '../components/AdvancedSlider'


import { Container } from '../components/item'


export const query = graphql`
  query {
    lamboImage: file(relativePath: { eq: "lambo-arial.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tintRoll: file(relativePath: { eq: "tint-roll.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    autoConcern: file(relativePath: { eq: "industry-concerns-auto.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    archConcern: file(relativePath: { eq: "industry-concerns-arch.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marineConcern: file(relativePath: { eq: "industry-concerns-marine.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blueBackground: file(relativePath: { eq: "blue-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    products: allShopifyProduct(filter: {vendor: {eq: "Raptect"}}, sort: {fields: handle}) {
      edges {
        node {
          id
          title
          handle
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    productsMetadata: shopifyunstableapi {
      products(first: 10, query: "Raptect") {
        edges {
          node {
            handle
            metafield(key: "blurb", namespace: "product") {
              value
            }
          }
        }
      }
    }
    applications: allApplicationsJson {
      edges {
        node {
          title
          path
          excerpt
          excerpt_image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const IndustryResourcesPage = ({data}) => {

  return (
    <Layout reverseNav>
      <SEO title="Industry Resources" />

      <Box as="section">
        <Container pb={13} pt={[12,14]} sx={{textAlign: 'center', color: 'black'}}>
          <Styled.h2 sx={{textTransform: 'uppercase', marginY: '40px'}}>Industry Resources</Styled.h2>
          <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: 'center'}}>
            <Card
              color='black'
              title="automotive"
              titleColor='black'
              roundEdges
              shadow
              text="Vehicle windows are damage-prone. Improve their safety, lifespan, and sun protection with professional tinting."
            >
            <Button to="/industry-resources/automotive" text="Learn More" />
            </Card>
            <Card
              color='black'
              title="marine"
              titleColor='black'
              roundEdges
              shadow
              text="Shipbuilding industries and boat repair businesses alike need quality tints to reduce UV damage, breakage risks, heat, and glare."
            >
            <Button to="/industry-resources/marine" text="Learn More" />
            </Card>
            <Card
              color='black'
              title="architecture"
              titleColor='black'
              roundEdges
              shadow
              text="Enhance window performance with Raptect heating, cooling, and UV solutions for homes and commercial buildings."
            >
            <Button to="/industry-resources/architecture" text="Learn More" />
            </Card>
          </Flex>
        </Container>
      </Box>

    <AdvancedSlider data={data.applications.edges} />

      <Box as="section">
        <Container>
          <Box as="section" bg="white" my={13}>
            <ShapedCard
              sx={{color: 'white'}}
              fluidImg={data.tintRoll.childImageSharp.fluid}
              to={'/products'} 
              title='Nano-Ceramic Window Tint'
              text="The highest-quality, longest-lasting window protection on the market. Raptect nano-ceramic window tint technology filters 99% of UV and and 80% of Infrared rays without blocking visible light. Long-lasting and cost-effective, it protects privacy, interiors, and the windows themselves while conserving energy and providing powerful temperature control."/>
          </Box>
        </Container>
      </Box>
      
      <BgImage
        fluid={data.blueBackground.childImageSharp.fluid}
        title="abstract background"
      >
        <Container py={13} sx={{textAlign: 'center', color: 'white', backgroundColor: ['blue', 'transparent']}}>
          <Styled.h2 sx={{marginY: '40px'}}>Industry Concerns</Styled.h2>
          <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center','flex-start'] }}>
            <Card
              color='white'
              title="Automotive"
              titleColor='white'
              text="Glare, heat gain, interior fading, privacy, security, UV skin exposure"
              fluidImg={data.autoConcern.childImageSharp.fluid}
            >
            </Card>
            <Card
              color='white'
              title="Marine"
              titleColor='white'
              text="Glare, visibility, heat gain, weather exposure, interior fading, UV exposure"
              fluidImg={data.marineConcern.childImageSharp.fluid}
            >
            </Card>
            <Card
              color='white'
              title="Architecture"
              titleColor='white'
              text="Energy efficiency, window damage, privacy, security"
              fluidImg={data.archConcern.childImageSharp.fluid}
            >
            </Card>
          </Flex>
        </Container>
      </BgImage>
      
    
    </Layout>
  )
  
}

export default IndustryResourcesPage
